import './App.css';
import Inicio from './pages/inicio';
import ErrorPage from './pages/ErrorPage';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import QuienesSomos from './pages/QuienesSomos';
import QueHacemos from './pages/QueHacemos';
import Experiencias from './pages/Experiencias';
import Contactenos from './pages/Contactenos';
import Donacion from './pages/Donacion';
import PanelAdmin from './pages/PanelAdmin';
import Regimen from './pages/Regimen';
import InicioState from './context/inicio/inicioState';
import QuienesSomosState from './context/quienesSomos/quienesSomosState';
import QueHacemosState from './context/queHacemos/queHacemosState';
import ExperienciasState from './context/experiencias/experienciasState';
import ContactenosState from './context/contactenos/contactenosState';
import DocumentosState from './context/documentos/documentosState';
import AuthState from './context/authentication/authState';
import SignIn from './pages/SignIn';
import tokenAuth from './config/token';
import Navigator from './navigator/Navigator';
import { Suspense } from 'react';
import Spinner from './components/layouts/Spinner';

// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <Inicio />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/quienessomos",
//         element: <QuienesSomos />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/quehacemos",
//         element: <QueHacemos />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/experiencias",
//         element: <Experiencias />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/contactenos",
//         element: <Contactenos />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/donacion",
//         element: <Donacion />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/panel",
//         element: <PanelAdmin />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/regimen",
//         element: <Regimen />,
//         errorElement: <ErrorPage />
//     },
//     {
//         path: "/signIn",
//         element: <SignIn />,
//         errorElement: <ErrorPage />
//     },
// ])

const token = localStorage.getItem("token");

if (token) {
    tokenAuth(token);
}

const App = () => {
    return (
        <InicioState>
            <QuienesSomosState>
                <QueHacemosState>
                    <ExperienciasState>
                        <ContactenosState>
                            <DocumentosState>
                                <AuthState>
                                    <Suspense fallback={<Spinner />}>
                                        {/* <RouterProvider router={router} /> */}
                                        <Navigator />
                                    </Suspense>
                                </AuthState>
                            </DocumentosState>
                        </ContactenosState>
                    </ExperienciasState>
                </QueHacemosState>
            </QuienesSomosState>
        </InicioState>
    );
}

export default App;