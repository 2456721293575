import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';

import DocumentosContext from './documentosContext';
import DocumentosReducer from './documentosReducer';

import {
    GET_DOCUMENTOS,
    GET_INFO_ERROR
} from '../../types';
import clienteAxios2 from '../../config/axiosUpload';

const DocumentosState = props => {

    const initialState = {
        documentos: null,
    }
    const [state, dispatch] = useReducer(DocumentosReducer, initialState);

    const getDocumentos = async () => {
        try {
            const respuesta = await clienteAxios.get(`/documentos`);
            console.log(respuesta);
            dispatch({
                type: GET_DOCUMENTOS,
                payload: respuesta.data.data
            })
        } catch (error) {
            dispatch({
                type: GET_INFO_ERROR,
                payload: error.response.data.msg
            })
        }
    }

    const postDocumento = async (file, data) => {
        file.append("ruta", "documentos");
        try {
            const respuesta = await clienteAxios.post(`/documentos`, data);
            const respuesta2 = await clienteAxios2.post(`/upload`, file);
            console.log(respuesta2);
            dispatch({
                type: GET_DOCUMENTOS,
                payload: respuesta.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDocumento = async (id) => {
        try {
            const respuesta = await clienteAxios.delete(`/documentos/${id}`);
           
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <DocumentosContext.Provider
            value={{
                documentos: state.documentos,
                getDocumentos,
                postDocumento,
                deleteDocumento
            }}
        >
            {props.children}
        </DocumentosContext.Provider>
    )
}

export default DocumentosState;