import React, { useContext, useEffect, useState } from 'react';
import quienesSomosContext from '../../context/quienesSomos/quienesSomosContext';
import { v4 } from 'uuid';

import urlImages from '../../config/urlImages';
import documentosContext from '../../context/documentos/documentosContext';
const PanelQuienesSomos = () => {

    const { banner, section1, section2, section3, updateQuienesSomos } = useContext(quienesSomosContext);

    const { documentos, postDocumento, deleteDocumento } = useContext(documentosContext);

    const [archivo, setArchivo] = useState(null);

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [bannerEdit, setBannerEdit] = useState({});
    const changeInfoBanner = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateQuienesSomos(f, {
            banner: bannerEdit
        });
    }
    const changeImagenBanner = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setBannerEdit({
            ...bannerEdit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateQuienesSomos(f, {
            banner: {
                ...bannerEdit,
                img: newName
            }
        });
        setArchivo(null);
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section1Edit, setSection1Edit] = useState({});
    const changeInfoSection1 = (e) => {
        e.preventDefault();
        const f = new FormData();
        // console.log(section1Edit.title)
        updateQuienesSomos(f, {
            section1: section1Edit
        });
    }
    const changeImagenSection1 = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setSection1Edit({
            ...section1Edit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateQuienesSomos(f, {
            section1: {
                ...section1Edit,
                img: newName
            }
        });
        setArchivo(null);
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section2Edit, setSection2Edit] = useState([]);
    const changeInfoSection2 = (e) => {
        e.preventDefault();
        const f = new FormData();
        // console.log(section2Edit.title)
        updateQuienesSomos(f, {
            section2: section2Edit
        });
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section3Edit, setSection3Edit] = useState([]);
    const [miembroDelete, setMiembroDelete] = useState('');
    const [newMiembro, setNewMiembro] = useState({
        nombre: '',
        cargo: '',
    });
    const { nombre, cargo } = newMiembro;
    const onChangeMiembro = (e) => {
        setNewMiembro({
            ...newMiembro,
            [e.target.name]: e.target.value
        })
    }
    const addMiembro = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setSection3Edit([
            ...section3Edit,
            { ...newMiembro, img: newName }
        ])
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateQuienesSomos(f, {
            section3: [
                ...section3Edit,
                { ...newMiembro, img: newName }
            ]
        });
    }
    const deleteMiembro = (e) => {
        e.preventDefault();
        setSection3Edit(section3Edit.filter((_, indx) => indx !== parseInt(miembroDelete)));
        const f = new FormData();
        updateQuienesSomos(f, {
            section3: section3Edit.filter((_, indx) => indx !== parseInt(miembroDelete))
        });
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [documentoEdit, setDocumentoEdit] = useState([]);
    const [documentoDelete, setDocumentoDelete] = useState('');
    const [newDocumento, setNewDocumento] = useState({
        nombreDoc: ''
    });

    const { nombreDoc } = newDocumento;

    const onChangeDoc = (e) => {
        setNewDocumento({
            ...newDocumento,
            [e.target.name]: e.target.value
        })
    }

    const addDocumento = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setDocumentoEdit([
            ...documentoEdit,
            { ...newDocumento, img: newName }
        ])
        const f = new FormData();
        f.append("archivo", archivo, newName);
        postDocumento(f, {nombre: nombreDoc, ruta: newName});
        console.log(newName);
    }
    const borrarDocumento = (e) => {
        e.preventDefault();
        setDocumentoEdit(documentoEdit.filter((item, indx) => item.id_documento !== parseInt(documentoDelete)));
        
        deleteDocumento(documentoDelete);
    }


    const subirArchivo = (e) => {
        setArchivo(e);
    }

    useEffect(() => {
        setBannerEdit(banner);
        setSection1Edit(section1);
        setSection2Edit(section2);
        setSection3Edit(section3);
        setDocumentoEdit(documentos);
    }, [section1]);

    return (
        <div className='panel_inicio'>
            <div className='panel_rows'>
                <div className='panel_items' style={{ backgroundColor: "var(--secundario_2)" }}>
                    <h2 style={{ color: "white" }}>Quienes Somos</h2>
                </div>
                <div className='panel_items'>
                    <h2>Sección 1</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoBanner}>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el Titulo de la sección' value={bannerEdit?.title} onChange={(e) => setBannerEdit({ ...bannerEdit, title: e.target.value })} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="nombres" type="text" placeholder="Contenido de la sección" maxLength="1000" value={bannerEdit?.body} onChange={(e) => setBannerEdit({ ...bannerEdit, body: e.target.value })} />
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenBanner}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 2</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoSection1}>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el Titulo de la sección' value={section1Edit?.title} onChange={(e) => setSection1Edit({ ...section1Edit, title: e.target.value })} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="nombres" type="text" placeholder="Contenido de la sección" maxLength="1000" value={section1Edit?.body} onChange={(e) => setSection1Edit({ ...section1Edit, body: e.target.value })} />
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenSection1}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 3</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoSection2}>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el contenido de Misión' maxLength="1000" value={section2Edit[0]?.body} onChange={(e) => setSection2Edit([{ ...section2Edit[0], body: e.target.value }, section2Edit[1], section2Edit[2]])} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el contenido de Visión' maxLength="1000" value={section2Edit[1]?.body} onChange={(e) => setSection2Edit([section2Edit[0], { ...section2Edit[1], body: e.target.value }, section2Edit[2]])} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el contenido de Valores' maxLength="1000" value={section2Edit[2]?.body} onChange={(e) => setSection2Edit([section2Edit[0], section2Edit[1], { ...section2Edit[2], body: e.target.value }])} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 4</h2>
                    <form id="formulario-contactanos" onSubmit={deleteMiembro}>
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion" value={miembroDelete} onChange={(e) => setMiembroDelete(e.target.value)}>
                                <option value="">Seleccione un miembro</option>
                                {section3Edit?.map((item, indx) =>
                                    <option value={indx} key={indx}>{item.nombre}</option>
                                )}
                            </select>
                            {miembroDelete != '' ? <img className='img_preview' src={`${urlImages}uploads/quienes_somos/${section3Edit[miembroDelete].img}`} alt="Preview"/> : null}
                            <button style={{ backgroundColor: "var(--secundario_2)" }}>Borrar Miembro Seleccionado<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={addMiembro}>
                        {/* <div className='formulario_items'>
                            <textarea name="nombre" type="text" placeholder='Ingrese el Nombre' value={nombre} onChange={onChangeMiembro} />
                            <textarea name="cargo" type="text" placeholder='Ingrese el Cargo' value={cargo} onChange={onChangeMiembro} />
                        </div> */}
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Agregar nuevo miembro<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Documentos legales</h2>
                    <form id="formulario-contactanos" onSubmit={borrarDocumento}>
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion" value={documentoDelete} onChange={(e) => setDocumentoDelete(e.target.value)}>
                                <option value="">Seleccione un documento</option>
                                {documentoEdit?.map((item, indx) =>
                                    <option value={item.id_documento} key={indx}>{item.nombre}</option>
                                )}
                            </select>
                            <button style={{ backgroundColor: "var(--secundario_2)" }}>Borrar documento seleccionado<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={addDocumento}>
                        <div className='formulario_items'>
                            <textarea name="nombreDoc" type="text" placeholder='Ingrese el Nombre del Documento' value={nombreDoc} onChange={onChangeDoc} />
                        </div>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Agregar nuevo Documento<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PanelQuienesSomos;