import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';

import documentosContext from '../context/documentos/documentosContext';
import urlImages from '../config/urlImages';

const Regimen = () => {

    const { documentos, getDocumentos } = useContext(documentosContext);

    useEffect(() => {
        getDocumentos();
    }, []);

    const [altura, setAltura] = useState(0);
    const [documentoSelected, setDocumentoSelected] = useState(null);

    useEffect(() => {
        setAltura(document.getElementById('wraper').offsetHeight);

        function handleResize() {
            setAltura(document.getElementById('wraper').offsetHeight);
        }
        window.addEventListener('resize', handleResize)
    }, [])

    const handlerOnClick = (documento) => {
        setDocumentoSelected(documento);
    }
    return (
        <div className='regimen'>
            <Header />
            <div className='inner_regimen' style={{ marginTop: `${altura}px` }}>
                <div className='sidebar'>
                    <div className='inner_sidebar'>
                        <ul style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: 0}}>
                            {documentos?.map(documento => (
                                <li className={documento.id_documento === documentoSelected?.id_documento ? 'regimen_active' : ''} onClick={() => handlerOnClick(documento)} key={documento.id_documento}>{documento.nombre}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='documentos' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className='inner_documentos'>
                        {documentoSelected ? (
                            <iframe
                                src={`${urlImages}uploads/documentos/${documentoSelected?.ruta}`}
                                title="Documento PDF"
                                width="100%"
                                height="800px"
                            />
                        ) : (
                            <h2 style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'var(--secundario_2)'}}>Seleccione un documento</h2>
                        )}
                        {/* <iframe
                            src={`${urlImages}uploads/documentos/${documentoSelected?.ruta}`}
                            title="Documento PDF"
                            width="100%"
                            height="800px"
                        /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Regimen;