export const GET_INFO_INICIO = 'GET_INFO_INICIO';
export const EDITAR_INICIO = 'EDITAR_INICIO';
export const GET_INFO_ERROR = 'GET_INFO_ERROR';

export const GET_INFO_QUIENES_SOMOS = 'GET_INFO_QUIENES_SOMOS';
export const EDITAR_QUIENES_SOMOS = 'EDITAR_QUIENES_SOMOS';

export const GET_INFO_QUE_HACEMOS = 'GET_INFO_QUE_HACEMOS';
export const EDITAR_QUE_HACEMOS = 'EDITAR_QUE_HACEMOS';

export const GET_INFO_EXPERIENCIAS = 'GET_INFO_EXPERIENCIAS';
export const EDITAR_EXPERIENCIAS = 'EDITAR_EXPERIENCIAS';
export const ADD_COMENT = 'ADD_COMENT';

export const GET_INFO_CONTACTENOS = 'GET_INFO_CONTACTENOS';
export const EDITAR_CONTACTENOS = 'EDITAR_CONTACTENOS';

export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const USUARIO_AUTENTICADO = 'USUARIO_AUTENTICADO';
export const CERRAR_SESION = 'CERRAR_SESION';
export const EDITAR_EXITOSO = 'EDITAR_EXITOSO';
export const MSG_RESET = 'MSG_RESET';

export const CHANGE_PASSWORD_EXITOSO = 'CHANGE_PASSWORD_EXITOSO';
export const CHANGE_ENFOQUE = 'CHANGE_ENFOQUE';

export const GET_DOCUMENTOS = 'GET_DOCUMENTOS';