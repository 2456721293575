import React, { useContext, useEffect } from 'react';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Section from '../components/panelAdmin/Section';
import inicioContext from '../context/inicio/inicioContext';
import quienesSomosContext from '../context/quienesSomos/quienesSomosContext';
import queHacemosContext from '../context/queHacemos/queHacemosContext';
import experienciasContext from '../context/experiencias/experienciasContext';
import contactenosContext from '../context/contactenos/contactenosContext';
import documentosContext from '../context/documentos/documentosContext';

const PanelAdmin = () => {

    const {getInicio} = useContext(inicioContext);
    const { getQuienesSomos } = useContext(quienesSomosContext);
    const { getQueHacemos } = useContext(queHacemosContext);
    const { getExperiencias } = useContext(experienciasContext);
    const { getContactenos } = useContext(contactenosContext);
    const { getDocumentos } = useContext(documentosContext);

    useEffect(() => {
        getInicio();
        getQuienesSomos();
        getQueHacemos();
        getExperiencias();
        getContactenos();
        getDocumentos();
    }, []);

    return (
        <div className='panel_admin'>
            <Header/>
            <Section/>
            <Footer/>
        </div>
    );
}

export default PanelAdmin;